body {
    color: #000;
    font-family: "FilsonProRegular", Arial, sans-serif;
    font-size: 15px;
}

.container {
    max-width: 100%;
}

.wrapper {
    max-width: 900px;
}

.button {
    background-color: #086a3e;
    color: white;
    border: none;
    padding: 8px 32px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 24px;
}

.button:disabled {
    opacity: 0.7;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.logo img {
    flex-shrink: 0;
    max-width: 50%;
    max-height: 100%;
}

.calendar-container {
    width: 75%;
}

@media all and (max-width: 772px) {
    .logo {
        padding: 0px !important;
    }

    .logo img {
        max-width: 80%;
    }

    .calendar-container {
        width: 100%;
    }

    .event-start {
        text-align: left !important;
    }
}

.text-container {
    background-color: #086a3e75;
    color: black;
    padding: 20px 12px;
    font-weight: 600;
    text-transform: uppercase;
}

input[type="radio"] {
    cursor: pointer;
}

/* Increase radio button's and checkbox size */
input[type="checkbox"],
input[type="radio"] {
    width: 20px;
    height: 20px;
}

.checkout-radio {
    top: 5px;
    position: relative;
    margin-right: 15px;
}

.side-bar {
    align-items: center;
    position: fixed;
    /* Change to fixed position */
    top: 0;
    right: 0;
    background-color: #f9f9f9;
    padding: 70px 30px 20px 30px;
    height: 100vh;
    /* Set height to 100% of the viewport height */
    overflow: auto;
    min-width: 250px;
    max-width: 20%;
    z-index: 999;
    /* Add a high z-index to ensure it's on top of other content */
}

.side-hr {
    border: 1px solid #086a3e;
    opacity: 0.7;
}

.close-btn {
    background-color: #f9f9f9;
    color: #086a3e;
    border: none;
    font-size: x-large;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 20px;
}

.next-button {
    background-color: #086a3e;
    color: white;
    border: none;
    padding: 8px 32px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 24px;
}

.time-slot-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.ticket-disabled {
    pointer-events: none;
    opacity: 0.5;    
}

.event-start {
    text-transform: none !important;
}

.promotion {
    background-color: #086a3e75;
}

.text-green {
    color: #529778
}