.react-calendar {
  width: 100%;
  max-width: 100%;
  background: transparent;
  border: 1px solid #086a3e;
  font-family: "FilsonProRegular", Arial, sans-serif;
  line-height: 1.125em;
  padding: 0px 0px 20px 0px;
  font-size: x-large;
}

@media all and (max-width: 900px) {
  .react-calendar {
    font-size: large;
  }

  abbr[title] {
    font-size: medium !important;
  }

  .react-calendar__tile {
    padding: 8px !important;
  }

  .address-field {
    text-align: left !important;
  }

  .react-calendar__navigation__label__labelText {
    font-size: medium !important;
  }

  .react-calendar__navigation {
    height: 50px !important;
    padding-bottom: 20px !important;
  }
}

.react-calendar__navigation__label__labelText::first-letter {
  text-transform: uppercase;
}

.react-calendar__navigation__label__labelText {
  font-size: x-large;
}

abbr[title] {
  text-decoration: none;
  font-size: x-large;
}

.bold-date {
  color: #000 !important;
  font-weight: 700 !important;
}

.disabled-date {
  color: #c0c0c0 !important;
  font-weight: 300 !important;
  pointer-events: none;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  background-color: #086a3e;
  display: flex;
  height: 50px !important;
  margin-bottom: 15px;
  padding-bottom: 55px;
  padding-top: 4px;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #fff;
}

.react-calendar__navigation button:disabled {
  color: #086a3e;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: fff;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.50em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.50em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  background: none;
  text-align: center;
  line-height: 16px;
  padding: 12px;
}

.react-calendar__tile:disabled {
  background-color: #fff;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #086a3e;
}

.react-calendar__tile--now {
  /*background: #ffff76;*/
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #086a3e;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #086a3e;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__arrow {
  display: inline;
  color: #fff;
  margin-top: 10px !important;
}

.react-calendar__navigation__label {
  font-weight: 700;
  color: #ffc529;
  font-size: x-large;
  display: flex;
  justify-content: start;
  padding: 12px 0px 4px 0px;
  text-transform: uppercase;
  pointer-events: none !important;
  justify-content: center;
}

.react-calendar__month-view__weekdays__weekday {
  background-color: transparent;
  color: #000;
  font-size: small;
  margin-bottom: 12px;
  font-weight: 700;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none !important;
}